<script setup>
import {computed, onMounted, ref} from "vue";
import Dialog from '@/Components/Shared/Dialogs/Dialog.vue';
import {useCookies} from '@vueuse/integrations/useCookies';
import dayjs from "dayjs";

const props = defineProps({
    message: Object,
});

const classes = computed(() => {
    let shared = [
        'font-medium', 'text-center', 'rounded', 'p-3',
    ];

    const classes = {
        green: ['bg-green-200', 'text-gray-900'],
        red: ['bg-red-200', 'text-gray-900'],
        orange: ['bg-orange-200', 'text-gray-900'],
        blue: ['bg-sky-200', 'text-gray-900'],
    };

    return shared.join(' ') + ' ' + classes[props.message.color].join(' ');
});

let cookies = null;
const isDialogOpen = ref(false);

const setIsOpen = (value) => {
    isDialogOpen.value = value;

    if (!cookies.get(`message-${props.message.id}`) && props.message.show_dialog_on_visit) {
        cookies.set(`message-${props.message.id}`, true, {
          expires: dayjs().add(1, 'month').toDate()
        });
    }
};

onMounted(() => {
    return;

    cookies = useCookies([`message-${props.message.id}`]);

    isDialogOpen.value = !cookies.get(`message-${props.message.id}`) && props.message.show_dialog_on_visit;
});
</script>

<template>
    <div :class="classes">
        <div :dusk="`message-${message.id}`" class="md:px-4 lg:px-8 xl:px-12">
            {{ message.banner_content }}

            <a href="#" class="underline" @click.prevent="setIsOpen(true)" v-if="message.has_dialog">Lees meer.</a>
        </div>

        <Dialog :is-open="isDialogOpen" :set-is-open="setIsOpen" :dusk="`message-${message.id}-modal`" v-if="message.has_dialog" class="md:min-w-[600px] lg:min-w-[800px] xl:min-w-[1000px]">
            <div v-html="message.dialog_markdown" class="cms-css"></div>
        </Dialog>
    </div>
</template>
